import * as React from "react";
import { Show, SimpleShowLayout, TextField,UrlField, FunctionField, useRecordContext, Link } from 'react-admin';
const AdminUserIDFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/adminuser?filter={"q":"${record.id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}
const AdminUserIDUpdateFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/adminuser?filter={"q":"${record.id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}
const InfluencerDetail = ({ ...props }) => (

    <Show title="Influencer" {...props}>
        <SimpleShowLayout>
            <TextField source="profile_id" label="Profile Id" />
            <TextField source='user.name' label="name" />
            <UrlField source="video_url" label="URL" />
            <TextField source="title" label="Title" />
            <TextField source="amount" label="Amount" />
            <TextField source="old_amount" label="Old Amount" />
            <TextField source="commends" label="Commends" />
            <TextField source="rating_count" label="Rating" />
            <TextField source="decline_reason" label="Decline Reason" />
            <FunctionField source="status" label="Status" render={record => {
                if (record['status'] === 'Reject') {
                    return (<span style={{ color: 'red' }}>Reject</span>)
                } else if (record['status'] === 'Pending') {
                    return (<span style={{ color: 'orange' }}>Pending</span>)
                } else {
                    return (<span style={{ color: 'green' }}>Approved</span>)
                }
            }} />
            <TextField source='description' label="Description" />
            <TextField source='submit_date' label="Submission Date" />
            <TextField source="approvedby.name" label="Approved By" />
            <TextField source="assignedby.name" label="Assigned By" />
            <TextField source="assignee.name" label="Assignee" />
            <TextField source="rejactedby.name" label="Rejacted By" />
            <TextField source="assigned_at" label="Assigned Date" />
            <TextField source="approved_at" label="Approved Date" />
            <TextField source="rejected_at" label="Rejected Date" />
        </SimpleShowLayout>
    </Show>
);
export default InfluencerDetail