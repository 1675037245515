import React from 'react';
import { Create, SimpleForm, TextInput, useNotify, Toolbar, ListButton, SaveButton } from 'react-admin';

import { StatusSelectInput } from '../../UI/Input/SelectInput'
import { onSboModulesCreate } from '../../Service/Validation/SboModulesValidation'
import Grid from '@material-ui/core/Grid';

const PageTitle = () => {
    return <span>Create Sbo Module</span>;
};

const FormToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label="Save" redirect="show" submitOnEnter={true} />
        {!props.saving && <ListButton label="Cancel" />}
    </Toolbar>
)
const SanitizedGrid = ({ basePath, ...props }) => {
    return (
        <Grid {...props} />
    );
};

const SboModulesCreate = (props) => {
    const notify = useNotify();

    const onError = (error) => {
        var errorArr = [];
        Object.keys(error.body.errors).forEach(function (key) {
            errorArr.push(error.body.errors[key])
        });
        notify(`Could not create item: ${errorArr.toString()}`, { type: 'error' });
    };
    return (
        <Create title={<PageTitle />} {...props} undoable={false} mutationOptions={{ onError }}>
            <SimpleForm toolbar={<FormToolbar />} warnWhenUnsavedChanges validate={onSboModulesCreate}>
                <SanitizedGrid container spacing={3} fullWidth>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Module Name" source="module_name" fullWidth />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <StatusSelectInput label="Status" source="status" fullWidth />
                    </Grid>
                </SanitizedGrid>
            </SimpleForm>
        </Create>
    );
}
export default SboModulesCreate