import React, { Fragment } from 'react'
import {
    List, Datagrid, Filter, ShowButton, TextInput, TextField, EditButton, BulkDeleteButton, FunctionField, DeleteButton, useRecordContext, Link
} from 'react-admin'
import useCheckPermissions from '../AdminPermissions/useCheckPermissions'

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
)
const ListActionButtons = ({ ...props }) => {
    return (<>
        <BulkDeleteButton {...props} undoable={false}
            confirmTitle="Delete File Record"
            confirmContent={"Are you sure you want to delete this record?"} />
    </>)
}

const AdminUserIDFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/adminuser?filter={"q":"${record.id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}
const AdminUserIDUpdateFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/adminuser?filter={"q":"${record.id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}
const InfluencerRatingsList = ({ ...props }) => {

    return (
        <List {...props} title="Influencer Ratings" bulkActionButtons={<ListActionButtons />} filters={<ListFilter />} sort={{ field: 'keyword', order: 'ASC' }}>

            <Datagrid >
                <TextField source="id" />
                <TextField source="rating_count" label="Ratings" />
                <TextField source="promotion_amount" label="Promotion Team Amount" />
                <TextField source="sales_amount" label="Sales Team Amount" />
                <TextField source="amount" label="Amount" />
                <TextField source="type" label="Type" />
                <AdminUserIDFiled source="createdby.name" label="Created by" />
                <AdminUserIDUpdateFiled source="updatedby.name" label="Updated by" />
                {useCheckPermissions('InfluencerRating_Edit')?<EditButton />:null}
                {useCheckPermissions('InfluencerRating_View')?<ShowButton />:null}
                {useCheckPermissions('InfluencerRating_Delete')?<DeleteButton />:null}
            </Datagrid>

        </List>
    )
}


export default InfluencerRatingsList