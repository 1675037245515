import React from 'react';
import { Edit, SimpleForm, Toolbar, ListButton, SaveButton,TextInput} from 'react-admin';
import { onSiteCarouselEdit } from '../../Service/Validation/SiteCarouselValidation'
import { SiteCarouselSiteSelectInput,SiteCarouselPageSelectInput, SiteCarouselAnimationSelectInput,SiteCarouselNavigationSelectInput,SiteCarouselAutoplaySelectInput,SiteCarouselStatusSelectInput } from '../../UI/Input/SelectInput'

const PageTitle = () => {
    return <span>Edit Site Carousel</span>;
};

const FormToolbar = props => (
    <Toolbar {...props} >       
        <SaveButton label="Save" redirect="list" submitOnEnter={true} />
        {!props.saving && <ListButton label="Cancel" />}
    </Toolbar>
)

const SiteCarouselEdit = (props) => (
    <Edit title={<PageTitle />} {...props} undoable={false}>
        <SimpleForm toolbar={<FormToolbar />} defaultValues={{ action: "full" }} validate={onSiteCarouselEdit}>
       
        <SiteCarouselSiteSelectInput label="Site" source="site" fullWidth disabled/>
        {/* <SiteCarouselPageSelectInput label="Page" source="page" fullWidth/> */}
        <TextInput label="Page" source="page" fullWidth/>
        <span>In milliseconds eg:1000 = 1 second</span>
        <TextInput label="Speed" source="speed"  fullWidth/>
        <span>In milliseconds eg:1000 = 1 second</span>
        <TextInput label=" Interval Time" source="intervaltime" placeholder="2000" fullWidth/>
        <SiteCarouselAnimationSelectInput label="Animation" source="animation" fullWidth/>
        <SiteCarouselAutoplaySelectInput label=" Autoplay" source="autoplay" fullWidth/>
        <SiteCarouselNavigationSelectInput label="Navigation" source="navigation" fullWidth/> 
        <SiteCarouselStatusSelectInput label="Status" source="status" fullWidth/>
        </SimpleForm>
    </Edit>
);

export default SiteCarouselEdit