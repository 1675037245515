import * as React from "react";
import { Show, SimpleShowLayout, TextField, FunctionField, useRecordContext, Link } from 'react-admin';

const AdminUserIDFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/adminuser?filter={"q":"${record.id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}
const AdminUserIDUpdateFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/adminuser?filter={"q":"${record.id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}
const InfluencerRatingsDetail = ({ ...props }) => (

    <Show title="Influencer Rating detail" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="rating_count" label="Rating Count" />
            <TextField source="promotion_amount" label="Promotion Team Amount" />
            <TextField source="sales_amount" label="Sales Team Amount" />
            <TextField source="amount" label="Amount" />
            <AdminUserIDFiled source="createdby.name" label="Created by" />
            <AdminUserIDUpdateFiled source="updatedby.name" label="Updated by" />
        </SimpleShowLayout>
    </Show>
);
export default InfluencerRatingsDetail