import React,{Fragment} from "react";
import {
  List,
  Datagrid,
  Filter,
  EditButton,
  Button,
  TextInput,
  useRecordContext,
  Link,
  TextField,
  FunctionField,
  ShowButton
} from "react-admin";
import BulkActiveButton from '../BuyBackWithdrawRequest/Action/Bulk.Active.Button';
import {WithdrawRequestStatusSelectInput,WithdrawRequestTypeSelectInput} from '../../UI/Input/SelectInput'
import useCheckPermissions from '../AdminPermissions/useCheckPermissions'

const BulkActionButtons = ({  ...props }) => {

  return (<Fragment>
      <BulkActiveButton label="Bulck Active"  {...props} />

  </Fragment>)
}
const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />    
    <WithdrawRequestStatusSelectInput label="Status" source="req_status" alwaysOn/>
  </Filter>
);

const ProfileidFiled = (props)=>{
  const record = useRecordContext();  
  return (
  <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event)=>event.stopPropagation()} />
)}

const IntroWithdrawRequestList = ({ ...props }) => {
 
  return (
    <Fragment>
    <List
      {...props}
      title="Withdraw Request"
      bulkActionButtons={<BulkActionButtons />}
      filters={<ListFilter />}
      sort={{ field: "name", order: "ASC" }}
    >
      <Datagrid >
        <TextField source="id" />
        <ProfileidFiled source="profile_id" label="Profile Id" />
        <TextField source="user.name" label="Name" />
        <TextField source="req_cvamount" label="CV amount" />  
        <TextField source="total_amount" label="Transfer Amount" />        
        <TextField source="req_type" label="Request Type" />    
        <FunctionField source="req_status" label="Status" render={record => {  
          if (record['req_status'] === 'Decline') {
            return ( <Button style={{ color:'red',border:'1px solid'}} label="Decline" size="small"></Button>)
          } else if(record['req_status'] === 'Pending') {
            return ( <Button style={{ color:'orange',border:'1px solid'}} label="Pending" size="small"></Button>)            
          }else if(record['req_status'] === 'Sent'){
            return ( <Button style={{ color:'green',border:'1px solid'}} label="Sent" size="small"></Button>)
          }else if(record['req_status'] === 'Cancelled'){
            return ( <Button style={{ color:'blue',border:'1px solid'}} label="Cancelled" size="small"></Button>)
          }                         
          }} /> 
           {useCheckPermissions('BuybackWithdrawRequest_Edit')?<FunctionField source="req_status" label="Edit Action" render={record => {  
            if(record['req_status'] === 'Pending') {
              return (  <EditButton/>  )            
            }      
                             
          }} /> :null}
                 {useCheckPermissions('BuybackWithdrawRequest_View')?<ShowButton/>:null} 
      </Datagrid>
    </List>
      
       </Fragment>
  );
};

export default IntroWithdrawRequestList;
