import React, { Fragment } from 'react'
import { List, Datagrid, Filter, ShowButton, TextInput, TextField, Button, EditButton, BulkDeleteButton, FunctionField, DeleteButton } from 'react-admin'
import { StatusSelectInput } from '../../UI/Input/SelectInput'
import useCheckPermissions from '../AdminPermissions/useCheckPermissions'

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <StatusSelectInput label="Status" source="status" />
    </Filter>
)
const ListActionButtons = ({ ...props }) => {
    return (<>
        <BulkDeleteButton {...props} undoable={false}
            confirmTitle="Delete File Record"
            confirmContent={"Are you sure you want to delete this record?"} />
    </>)
}
const AdminUserList = ({ ...props }) => {

    return (
        <List {...props} title="Admin Users" bulkActionButtons={<ListActionButtons />} filters={<ListFilter />} sort={{ field: 'name', order: 'ASC' }}>

            <Datagrid >
                <TextField source="id" />
                <TextField source="name" label="Name" />
                <TextField source="email" label="Email" />
                <TextField source="phone" label="Phone" />
                <FunctionField source="status" label="Status" render={record => {
                    if (record['status'] === 'Blocked') {
                        return (<span style={{ color: 'red' }}>Blocked</span>)
                    } else {
                        return (<span style={{ color: 'green' }}>Active</span>)
                    }
                }} />
                <FunctionField source="status" label="Status" render={record => {
                    if (record && record.role != null) {
                        if (record && record.role == 'SuperAdmin') {
                            return (
                                <Button style={{ color: 'green', border: '1px solid' }} label={record.role} size="small"></Button>
                            )
                        }else{
                            return(<Button style={{ color: 'red', border: '1px solid' }} label={record.role} size="small"></Button>)
                        }
                    }
                    return null;

                }} />

                {useCheckPermissions('AdminUser_Edit')?<EditButton />:null}
                {useCheckPermissions('AdminUser_View')?<ShowButton />:null}
            </Datagrid>

        </List>
    )
}


export default AdminUserList