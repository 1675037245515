import React from 'react';
import { Show,TextField, EditButton, ListButton,SimpleShowLayout, ImageField ,FunctionField ,BooleanField } from 'react-admin';
  
// const Actions = ({ basePath, data }) => {        
//     return (    
//     <PageToolbar>        
//         { checkPermission('sitecarouselslide.update')? <EditButton basePath={basePath} record={data} /> : null }
                
//     </PageToolbar>)
// };


const SiteCarouselSlideDetail = (props) => (    
    <Show title="SiteCarousel Slide Detail"  {...props}>        
        <SimpleShowLayout>            
            <TextField source="title" label="Title" />
            <TextField source="intro" label="Intro" />
            <TextField source="link" label="Link" />
            <TextField source="link_text" label="Link Text" />    
            <TextField source="status" label="Status" />  
            <TextField label="Order" source="order"/>
            <TextField source="Content Position" label="position" />       
            <ImageField source="image" title="title" />     
        </SimpleShowLayout>
    </Show>
);


export default SiteCarouselSlideDetail