
import React, { Fragment } from "react";
import { Show, FunctionField,TabbedShowLayout, TextField, ShowController, Tab, Datagrid, ReferenceManyField, useRecordContext, Pagination } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';


const PostPagination = ({ record, ...props }) => {
  return (<Pagination rowsPerPageOptions={[10, 20, 30, 50, 100, { value: props.total, label: 'All' }]} {...props} />)
}


const SboModulesDetail = ({ ...props }) => {
  const classes = useStyles();
  return (<ShowController {...props}>
    {controllerProps =>
      <Show title="Sbo Modules Detail" {...props}>
        <TabbedShowLayout>
          <Tab label="Summary">
            <TextField source="id" />
            <TextField source="module_name" label="Name" />
            <TextField label="Created by" source="createdby.name" />
            <TextField label="Updated by" source="createdby.name" />
            <FunctionField source="status" label="Status" render={record => {
              if (record['status'] === 'Deactive') {
                return (<span style={{ color: 'red' }}>Deactive</span>)
              } else {
                return (<span style={{ color: 'green' }}>Active</span>)
              }
            }} />
            <TextField source="keydetail" label="Permission Keys"/>
          </Tab>
        </TabbedShowLayout>
      </Show>
    }
  </ShowController>
  )
};
const useStyles = makeStyles({
  row: { verticalAlign: "top" },
  actionCell: { width: 100 },
  imageCell: { width: 160 },
  idCell: { width: 80 }
});
export default SboModulesDetail