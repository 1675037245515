import React, { Fragment } from 'react'
import { List, Datagrid, Filter, ShowButton,TextInput, TextField, EditButton,BulkDeleteButton, FunctionField,DeleteButton } from 'react-admin'
import { SboModulesStatusSelectInput } from '../../UI/Input/SelectInput'
import useCheckPermissions from '../AdminPermissions/useCheckPermissions'

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <SboModulesStatusSelectInput label="Status" source="status" />
    </Filter>
)
const ListActionButtons = ({ ...props }) => {
    return (<>
        <BulkDeleteButton {...props} undoable={false}
            confirmTitle="Delete File Record"
            confirmContent={"Are you sure you want to delete this record?"} />
    </>)
}
const SboModulesList = ({...props }) => {
    
    return (
        <List {...props} title="Sbo Modules" bulkActionButtons={<ListActionButtons />} filters={<ListFilter />} sort={{ field: 'module_name', order: 'ASC' }}>
            
                    <Datagrid >
                        <TextField source="id" />
                        <TextField source="module_name" label="Name" />  
                        <TextField label="Created by" source="createdby.name"/> 
                        <TextField label="Updated by" source="createdby.name"/>                                               
                        <FunctionField source="status" label="Status" render={record => {
                            if (record['status'] === 'Deactive') {
                                return (<span style={{ color: 'red' }}>Deactive</span>)
                            } else {
                                return (<span style={{ color: 'green' }}>Active</span>)
                            }
                        }} />
                        {useCheckPermissions('SboModules_Edit')?<EditButton  />   :null}                     
                        {useCheckPermissions('SboModules_View')?<ShowButton/>:null}
                    </Datagrid>
                
        </List>
    )
}


export default SboModulesList