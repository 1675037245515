import React,{Fragment} from "react";
import {
  List,
  Link,
  Datagrid,
  Filter,
  EditButton,Pagination,
  useRecordContext,
  Button,
  TextInput,
  TextField,
  ShowButton,
  TopToolbar ,
  DateInput,
  useListContext,
  FunctionField


} from "react-admin";
import { makeStyles } from '@material-ui/core';
import BulkRejectButton from './Action/Bulk.Reject.Button';
import BulkActiveButton from './Action/Bulk.Active.Button';
import DownloadIcon from '@material-ui/icons/GetApp';
import {RefundDocumentStatus,IncomePlanSelectInput} from '../../UI/Input/SelectInput'
import { stringify } from 'query-string';
const ListFilter = (props) => {
  const {
    
    setFilters,
    hideFilter
} = useListContext();
  return (  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />  
    {/* <DateInput source="current_date" lable="Date" alwaysOn/>     */}
    <RefundDocumentStatus label="Status" source="status" alwaysOn/>
  </Filter>)
};



const ProfileidFiled = (props)=>{
  const record = useRecordContext();  
  return (
  <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event)=>event.stopPropagation()} />
)}



const BulkActionButtons = ({ gift_id, ...props }) => {

    return (<Fragment>
       
        <BulkRejectButton label="Bulck Reject"  {...props} />
        <BulkActiveButton label="Bulck Approve"  {...props} />
    </Fragment>)
}

const ListActions  = (props) => {
  const record = useListContext();
 const filva = record.filterValues ? stringify(record.filterValues) : ''; 
 return ( <TopToolbar>
     <Button  href={`//sboportal.org.in/admin/api/v1/refundpaymentproof/export?${filva}`} label="Export Data" ><DownloadIcon /></Button>
  </TopToolbar>);
}

const PostPagination = ({ record, ...props }) => {    
  return (<Pagination rowsPerPageOptions={[10, 20, 30, 200,500,]} {...props} />)
}

const RefundPaymentProofList = ({ ...props }) => {
  const classes = useStyles()
  const [userstatusCreate, setUserStatusCreate] = React.useState(null)
  const handleStatusSelect = (record) => {
      setUserStatusCreate({ record: record })
  }

  return (
    <Fragment>
    <List 
      {...props}
      pagination={<PostPagination />}
      title="Refund Documents"
      
     
       bulkActionButtons={<BulkActionButtons />}
       actions={<ListActions/>}
       filters={<ListFilter />}
    >
      <Datagrid >
        <TextField source="id" />
        <ProfileidFiled source="profile_id"  label="Profile ID"/> 
        <TextField source="name" label="Name" />  
        <FunctionField source="status" label="Status" render={record => {  
          if (record['status'] === 'Decline') {
            return ( <Button style={{ color:'red',border:'1px solid'}} label="Decline" size="small"></Button>)
          } else if(record['status'] === 'Pending') {
            return ( <Button style={{ color:'orange',border:'1px solid'}} label="Pending" size="small"></Button>)            
          }else if(record['status'] === 'Approved'){
            return ( <Button style={{ color:'green',border:'1px solid'}} label="Approved" size="small"></Button>)
          }                        
          }} />      
          <TextField label="Approved by" source="approvedby.name"/> 
          <TextField label="Approved Date" source="approved_date"/> 
          <TextField label="declineby" source="declineby.name"/> 
        <TextField source="created_at" label="Created Date" /> 
      </Datagrid>
    </List>
      
       </Fragment>
  );
};
const useStyles = makeStyles({
  numberCell: { width: 60 },
  actionCell: { width: 100 },
  layoutCell: { width: 120 }
})
export default RefundPaymentProofList;
