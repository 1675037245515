import React from 'react';
import { Edit, SimpleForm, Toolbar, ListButton, SaveButton,Button, TextInput,ImageInput,ImageField,useRecordContext} from 'react-admin';
import { onSiteCarouselSlideEdit } from '../../Service/Validation/SiteCarouselSlideValidation'
import { SiteCarouselSlideStatusSelectInput,SiteCarouselSelectInput,SiteCarouselSlidePositionSelectInput} from '../../UI/Input/SelectInput'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import GridListTile from '@material-ui/core/GridListTile';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import SiteCarouselSlideImageDelete from './SiteCarouselSlide.Image.Delete'
import { parse } from "query-string";
const PageTitle = () => {
    return <span>Edit Site Carousel Slide</span>
};
const CancelButton = ({ carouselid }) => 
{
   
    console.log(carouselid);
    return(
    <Button component={Link} to={{ pathname: `/sitecarousel/${carouselid}/show/sitecarouselslide`, }} label="Cancel"></Button>
    )
};



const FormToolbar = props => {
     console.log(props.carouselid);
    const redirect = `/sitecarousel/${props.carouselid}/show/sitecarouselslide`;
    return (
    <Toolbar {...props} >       
        <SaveButton label="Save" redirect={redirect} submitOnEnter={true} />
        {!props.saving && <CancelButton carouselid={props.carouselid}/>}
    </Toolbar>
    )
}

const SliderImagesField = ({ source, onItemDelete}) => {
    const record = useRecordContext();
    //const classes = CustomerOfferImageStyles();    
    if (record.image_url === undefined) { return <p>No Image</p> }
    return (<div >
         <img style={{maxWidth:'300px'}} src={record.image_url.url} alt="" />
    </div>)
}



const SiteCarouselSlideEdit = (props) => {
    const [customerOfferImage, setCustomerOfferImage] = React.useState(null);
    const location = useLocation();
    const { slideid: slideid_string } = parse(location.search);
    const carouselid = slideid_string ? parseInt(slideid_string, 10) : "";
    
    const redirect = carouselid ? `/sitecarousel/${carouselid}/show/sitecarouselslide` : "show";
    return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
        <SimpleForm toolbar={<FormToolbar carouselid={carouselid} />}  defaultValues={{action:'full'}} validate={onSiteCarouselSlideEdit} initialValues={{ slideid:carouselid }} redirect={redirect}>
        <SiteCarouselSelectInput label="Site Carousel" source="site_carousel_id" fullWidth/>   
        <TextInput label="Title" source="title"  fullWidth/>
        <TextInput multiline label="Intro" source="intro" fullWidth disabled/>
        <TextInput label="Link" source="link"  fullWidth disabled/>
        <TextInput label="Link Text" source="link_text"  fullWidth disabled/>
        <SiteCarouselSlideStatusSelectInput label="Status" source="status" fullWidth />
        <TextInput label="Order" source="order" fullWidth/>
        <SiteCarouselSlidePositionSelectInput label="Content Position" source="position" fullWidth disabled/>
        <ImageInput className="commondropzone" source="banner_image" label="Banner Image" accept="image/*">
                <ImageField source="src" title="title" />
        </ImageInput>  
        <SliderImagesField source="sitecarouselslide" label="Existing Image" addLabel={true} onItemDelete={(item) => { setCustomerOfferImage(item) }} />
        <SiteCarouselSlideImageDelete customerOfferImage={customerOfferImage} onClose={() => { setCustomerOfferImage(null) }} />
        </SimpleForm>
    </Edit>
    )
};

export default SiteCarouselSlideEdit