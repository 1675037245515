

import { usePermissions } from 'react-admin';

const useCheckPermissions = (requiredpermission) => {
  const { permissions } = usePermissions();
  // if (permissions == '') { return false; }
  var datarole = permissions && permissions.role ? permissions.role : '';
  var datapermission = permissions && permissions.adminpermissions ? permissions.adminpermissions : '';
  if (datarole == '') { return false; }

  if (datarole == 'SuperAdmin') {
    return true;
  }

  if (datapermission == '') { return false; }

  if (datapermission.includes(requiredpermission)) {
    return true;
  }

  return false;

};

export default useCheckPermissions;
