import React, { Fragment } from 'react'
import {
    List, Datagrid, Filter, ShowButton, Button, DateInput, useListContext, TextInput, TextField, EditButton, BulkDeleteButton, FunctionField, DeleteButton, useRecordContext, Link
} from 'react-admin'
import { ContentWriterStatusSelectInput } from '../../UI/Input/SelectInput'
import DownloadIcon from '@material-ui/icons/GetApp';
import { DateRangeInput } from '../../UI/Input/input';
import useCheckPermissions from '../AdminPermissions/useCheckPermissions'

const ListFilter = (props) => {
    const {

        setFilters,
        hideFilter
    } = useListContext();

    return (<Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <DateRangeInput alwaysOn onClear={() => { hideFilter('daterange') }} onChange={(strdate) => {
            setFilters({ daterange: strdate })
        }} />
        <TextInput label="No of url" source="no_of_url" alwaysOn />
        <ContentWriterStatusSelectInput label="Status" source="status" />
       
    </Filter>
    )
}
const ListActionButtons = ({ ...props }) => {
    return (<>
        <BulkDeleteButton {...props} undoable={false}
            confirmTitle="Delete File Record"
            confirmContent={"Are you sure you want to delete this record?"} />
    </>)
}

const ExportHtml = (props) => {
    const record = useRecordContext();
    var itemid = (record && record.id > 0) ? record.id : 0;
    return <Button href={`//sboportal.org.in/admin/api/v1/exporthtml?itemid=${itemid}`} size="small"><DownloadIcon /></Button>
}
const ProfileidFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}
const ContentUrl = (props) => {
    const record = useRecordContext();
    return (
        
        <a label="URL" target='_blank'  href={record.content_url} size="small">
            <Button style={{ color:'purple',border:'1px solid'}} label="URL" size="small"></Button>
        </a>        
    )
}
const PreviewImgeUploaded = (props) => {
    const record = useRecordContext();
    return (
        
        <a target='_blank'  href={record.image_url} size="small">
            <img src={record.image_url} style={{ width:'50px',height:'50px'}} alt='previewimage'/>
        </a>        
    )
}

const ContentWriterList = ({ ...props }) => {

    return (
        <Fragment>
            <List {...props} title="Content Writer Keywords" bulkActionButtons={<ListActionButtons />} filters={<ListFilter />} sort={{ field: 'title', order: 'ASC' }}>

                <Datagrid >
                    <ProfileidFiled source="profile_id" label="Profile Id" />
                    <TextField source='keyword_count' label="Count" />
                    <TextField source="title" label="Title" />
                    <TextField source="amount" label="Amount" />
                    {/* <FunctionField source="status" label="Status" render={record => {
                        if (record['status'] === 'Reject') {
                            return (<span style={{ color: 'red' }}>Reject</span>)
                        } else {
                            return (<span style={{ color: 'green' }}>Approved</span>)
                        }
                    }} /> */}
                    {/* <TextField source='submit_date' label="Date" /> */}
                    {useCheckPermissions('ContentWriter_View')?<ShowButton />:null}
                    <ContentUrl />
                    <PreviewImgeUploaded />
                    <ExportHtml  />
                </Datagrid>

            </List>
        </Fragment>
    )
}


export default ContentWriterList