import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  ListButton,
  SaveButton,
  DateInput
} from "react-admin";

import Grid from "@material-ui/core/Grid";
import { WithdrawPaymentInfoSelectInput } from "../../UI/Input/SelectInput";
import { onWithdrawPaymentInfoEdit } from "../../Service/Validation/WithdrawPaymentInfoValidation";
const PageTitle = ({ record }) => {
  return <span>Category List: {record ? record.name : ""}</span>;
};
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const AgreementAndPaymentEdit = (props) => {
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm warnWhenUnsavedChanges toolbar={<FormToolbar />} defaultValues={{ action: "full" }} validate={onWithdrawPaymentInfoEdit} redirect="list">

      <SanitizedGrid container spacing={3} fullWidth>
          <Grid item sm={12} md={4} lg={4}>
          <TextInput source="profile_id" lable="Profile ID" fullWidth  />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
          <WithdrawPaymentInfoSelectInput  label="Select Type" fullWidth source="type"/>
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
          <DateInput source="task_date" lable="Date" fullWidth  />
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
          <TextInput label="Message" source="message" fullWidth />
          </Grid>
        </SanitizedGrid>
      </SimpleForm>
    </Edit>
  );
};

export default AgreementAndPaymentEdit;
