import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  FileInput,
  FileField,
  Toolbar,
  ListButton,
  SaveButton,
  DateInput,
} from "react-admin";
import { WithdrawPaymentInfoSelectInput } from "../../UI/Input/SelectInput";
import { onWithdrawPaymentInfoCreate } from "../../Service/Validation/WithdrawPaymentInfoValidation";
import Grid from "@material-ui/core/Grid";

const PageTitle = () => {
  return <span>Create Category</span>;
};

const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="show" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};

const AgreementAndPaymentCreate = (props) => {
  console.log(props);
  return (
    <Create title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm toolbar={<FormToolbar />} reValidateMode="onBlur" warnWhenUnsavedChanges validate={onWithdrawPaymentInfoCreate} redirect="list">

        <SanitizedGrid container spacing={3} fullWidth>
          <Grid item sm={12} md={4} lg={4}>
          <TextInput source="profile_id" lable="Profile ID" fullWidth  />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
          <WithdrawPaymentInfoSelectInput  label="Select Type" fullWidth source="type"/>
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
          <DateInput source="task_date" lable="Date" fullWidth  />
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
          <TextInput label="Message" source="message" fullWidth />
          </Grid>
        </SanitizedGrid>
      </SimpleForm>
    </Create>
  );
};
export default AgreementAndPaymentCreate;
