
export const onSingleUserBonusCreate = (values) => {

    const errors = {}
     if (required(values.profile_id)) {
        errors.profile_id = 'Profile ID is required'
    } 
    if (required(values.action)) {
        errors.action = 'action is required'
    } 
    if (required(values.bonus_type)) {
        errors.bonus_type = 'bonus type is required'
    } 
    if (required(values.amount)) {
        errors.amount = 'amount required'
    }
    if (required(values.remarks)) {
        errors.remarks ='remarks required'
    }


    return errors;
   
};

export const onBonusCreate = (values) => {

    const errors = {}
     if (required(values.profile_id)) {
        errors.profile_id = 'Profile ID is required'
    } 
    if (required(values.bonus_type)) {
        errors.bonus_type = 'Bonus Type is required'
    } 
    if (required(values.amount)) {
        errors.amount = 'amount required'
    }
    if (required(values.remarks)) {
        errors.remarks ='remarks required'
    }


    return errors;
   
};

export const onBonusBulckCreate = (values) => {

    const errors = {}
     if (required(values.attachments)) {
        errors.attachments = 'Upload file is required'
    } 
    
    


    return errors;
   
};

export const onUploadWithdrawPaymentInfoCreate = (values) => {

    const errors = {}
     if (required(values.attachments)) {
        errors.attachments = 'Upload file is required'
    } 
    if (required(values.task_date)) {
        errors.task_date ='Date is required'
    }
    if (required(values.type)) {
        errors.type ='Type is required'
    }
    


    return errors;
   
};























const isEmpty = (value: any) =>{
    return (typeof value === 'undefined' || value === null || value === '')
}

export const required = (value) =>{
    return isEmpty(value)
}

