export const onSiteCarouselCreate = (values) => {

    const errors = {}
    if (!values.page) {
        errors.page = ['Page is required']
    }
    if (!values.site) {
        errors.site = ['Site is required']
    }
    if (!values.status) {
        errors.status = ['Status is required']
    }
    return errors

};

export const onSiteCarouselEdit = (values) => {

    const errors = {}
    if (!values.page) {
        errors.page = ['Page is required']
    }
    if (!values.site) {
        errors.site = ['Site is required']
    }
    if (!values.status) {
        errors.status = ['Status is required']
    }
  

    return errors
};