import * as React from "react";
import { Show, SimpleShowLayout, TextField } from 'react-admin';
const AgreementAndPaymentDetail = ({...props }) => (

    <Show title="Category Detail" {...props}>
        <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="profile_id" label="Profile Id" />
        <TextField source="type" label="Type" />
        <TextField source="task_date" label="Date" />
        <TextField source="message" label="Message" />
        </SimpleShowLayout>
    </Show>
     );
export default AgreementAndPaymentDetail