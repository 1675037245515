import React, { Fragment } from "react";
import {
  List,
  Datagrid,
  Filter,
  ChipField,
  Button, useRecordContext,
  TextInput,
  TextField,
  FunctionField,
  ShowButton,
  useListContext,
  TopToolbar,
  DateInput,
  DeleteButton,
} from "react-admin";
import { DateRangeInput } from '../../UI/Input/input';
import DownloadIcon from '@material-ui/icons/GetApp';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import MissedVideoCallOutlinedIcon from '@mui/icons-material/MissedVideoCallOutlined';
import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined';
import BulkDeleteButton from '../PublicCloudStorage/Action/Bulk.Delete.Button';
import BulkActiveButton from '../PublicCloudStorage/Action/Bulk.Active.Button';
import BulkRejectButton from '../PublicCloudStorage/Action/Bulk.Reject.Button'
 import AproveButton from '../PublicCloudStorage/Action/Approve.Button';
import RejectButton from '../PublicCloudStorage/Action/Reject.Button';
import useCheckPermissions from '../AdminPermissions/useCheckPermissions'
import { stringify } from 'query-string';
import { UserStatusSelectInput, FileTypeSelectInput } from '../../UI/Input/SelectInput'
const ListFilter = (props) => {
  const {

    setFilters,
    hideFilter
  } = useListContext();
  return (<Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <UserStatusSelectInput label="Status" source="status" alwaysOn />
    <FileTypeSelectInput label="File Type" source="file_type" alwaysOn />
    <DateInput source="current_date" lable="Date" alwaysOn />
    <DateRangeInput alwaysOn onClear={() => { hideFilter('daterange') }} onChange={(strdate) => {
      setFilters({ daterange: strdate })
    }} />

  </Filter>)
};
const BulkActionButtons = ({ ...props }) => {
 

  return (<Fragment>
     
      <BulkDeleteButton   {...props} />
      <BulkActiveButton   {...props}  />
      <BulkRejectButton   {...props}  />
  </Fragment>)
}
const DownloadInvoice = (props) => {
  const record = useRecordContext();
  var itemid = (record && record.id > 0) ? record.id : 0;
  return <Button label="Download" href={`//127.0.0.1:8000/admin/api/v1/taskdownloadfileaction/${itemid}`} size="small"><DownloadIcon /></Button>
}


const ListActions = (props) => {
  const record = useListContext();
  const filva = record.filterValues ? stringify(record.filterValues) : '';

  // return (
  //   <TopToolbar>
  //     <Button href={`//sboportal.org.in/admin/api/v1/user/export?${filva}`} label="User Export" ><DownloadIcon /></Button>
  //   </TopToolbar >)
};

const PublicCloudStorageList = ({ record, ...props }) => {

  return (
    <Fragment>
      <List
        {...props}
        title="Task List"
        bulkActionButtons={<BulkActionButtons />}

        sort={{ field: "name", order: "ASC" }}
        actions={<ListActions />}
        filters={<ListFilter />}
      >
        <Datagrid >
          <TextField source="id" />
          <TextField source="profile_id" label="Profile ID" />
          <TextField source="name" label="Name" />
          {/* <TextField source="file_type" label="File Type" /> */}
          <TextField source="category" label="Category" />
          <ChipField source="file_size" label="File Size" />
          <FunctionField source="status" label="Status" render={record => {
            if (record['status'] === 'Decline') {
              return (<Button style={{ color: 'red',  }} label="Reject" size="small"></Button>)
            } else if (record['status'] === 'Pending') {
              return (<Button style={{ color: 'orange', }} label="Pending" size="small"></Button>)
            } else if (record['status'] === 'Active') {
              return (<Button style={{ color: 'green', }} label="Approved" size="small"></Button>)
            } else if (record['status'] === 'Suspended') {
              return (<Button style={{ color: 'blue',}} label="Suspended" size="small"></Button>)
            }
          }} />
          <FunctionField source="status" label="Type" render={record => {
            if (record['file_type'] === 'Image') {
              return (<InsertPhotoOutlinedIcon />
                // <span>Payment Proof <br /><br />
                //   <img style={{ maxWidth: '150px' }} src={record['fileurl']} alt={record.name} /></span>
              )
            } else if (record['file_type'] === 'Document') {
              return (<ArticleOutlinedIcon />)
            } else if (record['file_type'] === 'Video') {
              return (<MissedVideoCallOutlinedIcon />)

            } else {
              return (<RedeemOutlinedIcon />)
            }
          }} />
          {useCheckPermissions('PublicCloudStorageList_View')?<ShowButton />:null}

          {useCheckPermissions('PublicCloudStorageList_Download')?<DownloadInvoice label="Download" />:null}
          {useCheckPermissions('PublicCloudStorageList_Create')?<AproveButton label="Approve"   {...props}/>:null}
          {useCheckPermissions('PublicCloudStorageList_Create')?<RejectButton label="Reject"  {...props} />:null}
          {useCheckPermissions('PublicCloudStorageList_Delete')?<DeleteButton />:null}
         
        </Datagrid>
      </List>

    </Fragment>
  );
};

export default PublicCloudStorageList;
