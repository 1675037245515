
import React, { Fragment } from "react";
import { Show, TabbedShowLayout, TextField, ShowController, Tab, Datagrid, ReferenceManyField, useRecordContext, Pagination } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import BulkAssignButton from '../AdminUser/Action/Bulk.Assign.Button';


const PostPagination = ({ record, ...props }) => {
  return (<Pagination rowsPerPageOptions={[10, 20, 30, 50, 100, { value: props.total, label: 'All' }]} {...props} />)
}
const ProfileidFiled = (props) => {
  const record = useRecordContext();
  return (
    <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event) => event.stopPropagation()} />
  )
}

const BulkActionButtons = ({ record, ...props }) => {
  return (<Fragment>
    <BulkAssignButton label="Bulck Assign"  {...props} />


  </Fragment>)
}

const AdminUserDetail = ({ ...props }) => {
  const classes = useStyles();
  return (<ShowController {...props}>
    {controllerProps =>
      <Show title="Admin User Detail" {...props}>
        <TabbedShowLayout>
          <Tab label="Summary">
            <TextField source="id" />
            <TextField source="name" label="Name" />
            <TextField source="email" label="Email" />
            <TextField source="phone" label="Phone" />
            <TextField source="status" label="Status" />
          </Tab>
          <Tab label="Influencer Task">

            <ReferenceManyField pagination={<PostPagination />} reference={'influencer'} target="id" label="" filter={{ uid: 0, status: "Pending" }} addLabel={false} >
              <Datagrid bulkActionButtons={<BulkActionButtons />} classes={{ row: classes.row }} >
                <TextField source="id" />
                <ProfileidFiled source="profile_id" label="Profile Id" />
                <TextField source='user.name' label="name" />
                <TextField label="Status" source="status" sortable={false} />
                <TextField label="Submited Date" source="submit_date" sortable={false} />

              </Datagrid>
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    }
  </ShowController>
  )
};
const useStyles = makeStyles({
  row: { verticalAlign: "top" },
  actionCell: { width: 100 },
  imageCell: { width: 160 },
  idCell: { width: 80 }
});
export default AdminUserDetail