import React, { Fragment } from "react";
import {
  List,
  Datagrid,
  Filter,
  TextInput,
  TextField,
  EditButton,
  BulkDeleteButton,
  DeleteButton,
} from "react-admin";
import { VideoTypeSelectInput } from "../../UI/Input/SelectInput";
import useCheckPermissions from '../AdminPermissions/useCheckPermissions'

const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <VideoTypeSelectInput label="Type" source="urltype" alwaysOn fullWidth />
  </Filter>
);
const ListActionButtons = ({ ...props }) => {
  return (
    <>
      <BulkDeleteButton
        {...props}
        undoable={false}
        confirmTitle="Delete File Record"
        confirmContent={"Are you sure you want to delete this record?"}
      />
    </>
  );
};
const LanguageVideoList = ({ ...props }) => {
  return (
    <List
      {...props}
      title="Video"
      bulkActionButtons={<ListActionButtons />}
      filters={<ListFilter />}
      sort={{ field: "name", order: "ASC" }}
    >
      <Datagrid rowClick={"show"}>
        <TextField source="id" />
        <TextField source="name" label="Name" />
        <TextField source="description" label="Description" />
        <TextField source="urltype" label="Url Type" />             
        {useCheckPermissions('LanguageVideo_Edit')?<EditButton />:null}
        {useCheckPermissions('LanguageVideo_View')?<DeleteButton />:null}
      </Datagrid>
    </List>
  );
};

export default LanguageVideoList;
