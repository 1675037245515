export const SiteCarouselSlideUpload = (data) => {
    var formdata = new FormData()   
    formdata.append('id', data.id?data.id:'')
    formdata.append('title', data.title ? data.title :'' )
    formdata.append('site_carousel_id', data.site_carousel_id ? data.site_carousel_id :'' )
    formdata.append('intro', data.intro ? data.intro :'' )  
    formdata.append('link', data.link ? data.link :'' )
    formdata.append('link_text', data.link_text ? data.link_text :'' )
    formdata.append('status', data.status ? data.status :'' )
    formdata.append('order', data.order ? data.order :'' )
    formdata.append('position', data.position ? data.position :'' )
    formdata.append('action','full')
    if(data.banner_image){
            formdata.append('banner_image',data.banner_image.rawFile)
    }

    return formdata;
}