import React from "react";
import {
  Edit,
  TabbedForm,
  FormTab,
  useNotify,
  TextInput,
  Toolbar,
  ListButton,
  SaveButton,
} from "react-admin";

import { SboModulesStatusSelectInput } from "../../UI/Input/SelectInput";
import { onSboModulesEdit } from "../../Service/Validation/SboModulesValidation";
import Grid from "@material-ui/core/Grid";
const PageTitle = ({ record }) => {
  return <span>Sbo Module: {record ? record.module_name : ""}</span>;
};
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const SboModulesEdit = (props) => {
  const notify = useNotify();

  const onError = (error) => {
    var errorArr = [];
    Object.keys(error.body.errors).forEach(function (key) {
      errorArr.push(error.body.errors[key]);
    });
    notify(`Could not create item: ${errorArr.toString()}`, { type: "error" });
  };
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
      <TabbedForm warnWhenUnsavedChanges
        toolbar={<FormToolbar />}
        defaultValues={{ action: "full" }}
        validate={onSboModulesEdit}
      >
        <FormTab label="Edit Info">
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} md={6} lg={6}>
              <TextInput label="Module Name" source="module_name" fullWidth />
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
              <SboModulesStatusSelectInput label="Status" source="status" fullWidth />
            </Grid>
          </SanitizedGrid>         
        </FormTab>      
      </TabbedForm>
    </Edit>
  );
};

export default SboModulesEdit;
