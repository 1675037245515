export const onInfluencerRatingsCreate = (values) => {

    const errors = {}
    if (required(values.rating_count)) {
        errors.rating_count = 'Rating count is required'
    }
    if (required(values.sales_amount)) {
        errors.sales_amount = 'Sales team amount required'
    }
    if (required(values.promotion_amount)) {
        errors.promotion_amount = 'Promotion team amount required'
    }
    return errors;

};

export const onInfluencerRatingsEdit = (values) => {

    const errors = {}
    if (required(values.rating_count)) {
        errors.rating_count = 'Rating count is required'
    }
    if (required(values.sales_amount)) {
        errors.sales_amount = 'Sales team amount required'
    }
    if (required(values.promotion_amount)) {
        errors.promotion_amount = 'Promotion team amount required'
    }
    return errors;
};







const REGEX_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const REGEX_MOBILE = /^([0-9\s\-\+\(\)]*)$/
const REGEX_LETTER = /^[A-Za-z]+$/

const isEmpty = (value: any) => {
    return (typeof value === 'undefined' || value === null || value === '')
}

export const required = (value) => {
    return isEmpty(value)
}

export const minLength = (length, value) => {
    return (!isEmpty(value) && value.length < length)
}

export const maxLength = (length, value) => {
    return (!isEmpty(value) && value.length > length)
}

export const email = (value) => {
    return regex(REGEX_EMAIL, value)
}

export const mobile = (value) => {
    return regex(REGEX_MOBILE, value)
}
export const letterOnly = (value) => {
    return regex(REGEX_LETTER, value);
}
export const regex = (pattern, value) => {
    return (!isEmpty(value) && typeof value === 'string' && !pattern.test(value))
}