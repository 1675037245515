import React, { Fragment } from 'react'
import { List, Datagrid, Filter, TextInput, TextField, FunctionField, useRecordContext, EditButton, SimpleList, Button, BulkDeleteButton } from 'react-admin'
import { makeStyles, Chip } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { SiteCarouselStatusSelectInput } from '../../UI/Input/SelectInput';
import { Link } from 'react-router-dom';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <SiteCarouselStatusSelectInput label="Status" source="status" allowEmpty={true} emptyText="All" alwaysOn />
    </Filter>
)



const ListActionButtons = ({ ...props }) => {
    return (<Fragment>
        <BulkDeleteButton {...props} undoable={false}
            confirmTitle="Delete Site Carousel Record"
            confirmContent={"Are you sure you want to delete this record? Also delete carousel based files "} />
    </Fragment>)
}

const SiteCarouselSlideListButton = ({ ...props }) => {
    const record = useRecordContext();
    if (record.slides && record.slides.length > 0) {

        return (<Button {...props} component={Link} to={{ pathname: `/sitecarousel/${record.id}/show/sitecarouselslide` }} label={record.slides.length + ' Slides'} onClick={(event) => event.stopPropagation()}></Button>)
    }
}
const SiteCarouselSlideListButtonCreate = ({ ...props }) => {
    const record = useRecordContext();


    return (<Button {...props} component={Link} to={{ pathname: `/sitecarouselslide/create`, search: `?slideid=${record.id}` }} label={'Add Slide'} onClick={(event) => event.stopPropagation()}></Button>)

}

const SiteCarouselList = (props) => {
    const classes = useStyles()
    return (
        <List {...props} title="carousel" exporter={false} bulkActionButtons={<ListActionButtons />} filters={<ListFilter />}>

            <Datagrid classes={{ row: classes.row }} rowClick="show">
                {/* <TextField cellClassName={classes.numberCell} source="id" /> */}
                <TextField source="site" label="Site" />
                <TextField source="page" label="Page" />
                <TextField source="speed" label=" Speed" />
                <TextField source="intervaltime" label=" Interval Time" />
                <TextField source="animation" label="Animation" />
                <TextField source="autoplay" label=" Autoplay" />
                <TextField source="navigation" label=" Navigation" />
                <TextField source="status" label="Status" />
                <SiteCarouselSlideListButtonCreate label="Create Slide"/>
                {<SiteCarouselSlideListButton label="Show Slide" cellClassName={classes.SiteCarouselSlideCell} color="secondary" />}
                {<EditButton cellClassName={classes.actionCell} sortable={false} />}
            </Datagrid>


        </List>
    );
}

const useStyles = makeStyles({
    row: { verticalAlign: "top", },
    numberCell: { width: 40 },
    actionCell: { width: 100 },
    bankCell: { width: 120 }
})
export default SiteCarouselList