import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import clsx from 'clsx';
import DashboardData from './Dashboard.Data'
import DashboardCounts from './Dashboard.Counts';
import DashboardListActiveUser from './Dashboard.List.ActiveUser';
import DashboardListPendingUser from './Dashboard.List.PendingUsers';
import DashboardListWithdrawalRequest from './Dashboard.List.Withdrawal.Request';

const Dashboard = ({ classes, statdata, dataLoading, ...props }) => {
    return (
    <div className={classes.root}>
         <Grid item xs={12}>
            <h2>Hi, <b className={clsx( classes.colorgreen)}>{statdata.counts ?statdata.counts.adminuser.all:''}</b> Welcome </h2>
         </Grid>
            {statdata.counts && <Grid container className="dashbrd" spacing={2}>
                <Grid item xs={12}>
                    <DashboardCounts counts={statdata.counts}/>
                </Grid>   
            </Grid>}
            {/* <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <DashboardListActiveUser items={statdata.list ? statdata.list.activeuser : []} dataLoading={dataLoading} />
                </Grid>             
                <Grid item xs={12} md={4}>
                    <DashboardListPendingUser items={statdata.list ? statdata.list.pendinguser : []} dataLoading={dataLoading} />
                </Grid>   
                <Grid item xs={12} md={4}>
                    <DashboardListWithdrawalRequest items={statdata.list ? statdata.list.withdrawrequest : []} dataLoading={dataLoading} />
                </Grid>
            </Grid> */}
        </div>
)
            }
const styles = theme => ({
    centeralign: {
        textalign: 'center', fontsize: '14px',
    },

});
export default withStyles(styles)(DashboardData(Dashboard))