import { fetchPost } from '../Service/ApiService'
import { authmodel } from '../Provider/AuthProvider';

export const activeRefundDocument = (selectedIds, resolve, reject) => {
   

    try {
        fetchPost(`refunddocument/bulckapprove`, selectedIds, {
            success: (res) => { resolve(res) },
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to process operation" })
    }
};

export const rejectRefundDocument = (selectedIds, resolve, reject) => {
   

    try {
        fetchPost(`refunddocument/bulckdecline`, selectedIds, {
            success: (res) => { resolve(res) },
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to process operation" })
    }
};






