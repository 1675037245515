import React from "react";
import {
  Create,
  SimpleForm,
  FileField,
  Toolbar,
  FileInput,
  ListButton,
  SaveButton,
  DateInput,
  TextInput

} from "react-admin";
import { onUploadWithdrawPaymentInfoCreate } from "../../Service/Validation/AddBonusValidation";
import Grid from "@material-ui/core/Grid";
import { WithdrawPaymentInfoSelectInput } from "../../UI/Input/SelectInput";

const PageTitle = () => {
  return <span>Upload Withdraw Credit List</span>;
};

const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="show" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};

const AddWithdrawPaymentInfoCreate = (props) => {

  return (
    <Create title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm toolbar={<FormToolbar />} reValidateMode="onBlur" warnWhenUnsavedChanges validate={onUploadWithdrawPaymentInfoCreate}>
        <SanitizedGrid container spacing={3} fullWidth>
          <Grid item sm={12} md={6} lg={4}>

            <DateInput source="task_date" lable="Date" fullWidth  />
          </Grid>
          <Grid item sm={12} md={6} lg={4}>

          <WithdrawPaymentInfoSelectInput  label="Select Type" fullWidth source="type"/>
          </Grid>
          <Grid item sm={12} md={6} lg={4}>

            <TextInput label="Message" source="message" fullWidth />
          </Grid>
          <FileInput source="attachments">
            <FileField source="src" title="title" />
          </FileInput>


        </SanitizedGrid>

      </SimpleForm>
    </Create>
  );
};
export default AddWithdrawPaymentInfoCreate;
