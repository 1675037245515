export const onWithdrawPaymentInfoEdit = (values) => {

    const errors = {}

    if (required(values.profile_id)) {
        errors.profile_id = 'profile id is required'
    }
    if (required(values.type)) {
        errors.type = 'Type required'
    }
    if (required(values.task_date)) {
        errors.task_date = ['Date is required']
    }
    return errors;

};

export const onWithdrawPaymentInfoCreate = (values) => {

    const errors = {}
    if (required(values.profile_id)) {
        errors.profile_id = 'profile id is required'
    }
    if (required(values.type)) {
        errors.type = 'Type required'
    }
    if (required(values.task_date)) {
        errors.task_date = ['Date is required']
    }
    return errors;
    

};
const isEmpty = (value: any) => {
    return (typeof value === 'undefined' || value === null || value === '')
}
export const required = (value) => {
    return isEmpty(value)
}