import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';

import { loginUser, onSignIn, onSignOut, getAuthUser } from '../Model/Auth'

export default (type, params) => {
    // called when the user attempts to log in
    if (type === AUTH_LOGIN) {
        const { username, password } = params

        return new Promise((resolve, reject) => {
            loginUser({ username, password }, (res) => {
                onSignIn(res.data)
                resolve()
            }, (res) => {
                reject(res.message)
            })
        })


        //localStorage.setItem('username', username);
        // accept all username/password combinations
        //return Promise.resolve()
    }
    // called when the user clicks on the logout button
    if (type === AUTH_LOGOUT) {
        onSignOut()
        return Promise.resolve()
    }
    // called when the API returns an error
    if (type === AUTH_ERROR) {
        const { status } = params;
        if (status === 401 || status === 403) {
            localStorage.removeItem('username')
            return Promise.reject();
        }
        return Promise.resolve();
    }
    // called when the user navigates to a new location
    if (type === AUTH_CHECK) {
        return getAuthUser() ? Promise.resolve() : Promise.reject()
    }

    if (type === AUTH_GET_PERMISSIONS) {
        const roles = JSON.parse(localStorage.getItem('rauth_admin')) ? JSON.parse(localStorage.getItem('rauth_admin')) : '';
        return roles ? Promise.resolve(roles) : Promise.reject();
    }


    return Promise.reject('Unknown method')
};