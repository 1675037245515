import React from 'react';
import { Create, SimpleForm, Toolbar,FileInput, ListButton, SaveButton,Button, TextInput,ImageInput,ImageField} from 'react-admin';
import { onSiteCarouselSlideCreate } from '../../Service/Validation/SiteCarouselSlideValidation'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { parse } from "query-string";
import {authmodel} from '../../Provider/AuthProvider';
import { SiteCarouselSlideStatusSelectInput,SiteCarouselSelectInput, SiteCarouselSlidePositionSelectInput } from '../../UI/Input/SelectInput'

const PageTitle = () => {
    return <span>Create Site Carousel Slide</span>;
};

const CancelButton = ({carouselid}) => (
    <Button component={Link} to={{pathname: `/sitecarousel/${carouselid}/show/sitecarouselslide`,}} label="Cancel"></Button>
);

const FormToolbar = props => {
    const redirect = `/sitecarousel/${props.carouselid}/show/sitecarouselslide`;
    return (
    <Toolbar {...props} >       
        <SaveButton label="Save" redirect={redirect} submitOnEnter={true} />
        {!props.saving && <CancelButton carouselid={props.carouselid}/>}
    </Toolbar>
    )
}

const SiteCarouselSlideCreate = (props) => {
    const location = useLocation();
    //console.log(location.search); 
    const { slideid: slideid_string } = parse(location.search);
    const carouselid = slideid_string ? parseInt(slideid_string, 10) : "";
    
    const redirect = carouselid ? `/sitecarousel/${carouselid}/show/sitecarouselslide` : "show";
return(
    <Create title={<PageTitle />} {...props} undoable={false}>
    <SimpleForm toolbar={<FormToolbar carouselid={carouselid} />} validate={onSiteCarouselSlideCreate} initialValues={{ slideid:carouselid }} redirect={redirect}>   
    <SiteCarouselSelectInput label="Site Carousel" source="site_carousel_id" fullWidth/>   
    <TextInput label="Title" source="title"  fullWidth />
    <TextInput multiline label="Intro" source="intro" fullWidth defaultValue="Intro" disabled/>
    <TextInput label="Link" source="link" fullWidth defaultValue="Link" disabled/>
    <TextInput label="Link Text" source="link_text"  fullWidth defaultValue="Link Text" disabled/>
    {/* <ImageInput source="banner_image" label="Banner Image" accept="image/*">
             <ImageField source="src" title="title" />
     </ImageInput> */}
      <FileInput source="banner_image" label="Upload Image" accept="image/*">
                <ImageField source="src" title="title" />
            </FileInput>
    <SiteCarouselSlideStatusSelectInput label="Status" source="status" fullWidth/>
    <TextInput label="Order" source="order" fullWidth/>
    <SiteCarouselSlidePositionSelectInput label="Content Position" source="position" fullWidth disabled/>
    </SimpleForm>
</Create>
)
};

export default SiteCarouselSlideCreate