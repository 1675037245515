
import React, { Fragment } from "react";
import { Show, TabbedShowLayout, Button, BulkDeleteButton, List, TextField, ShowController, Tab, Datagrid, ReferenceManyField, useRecordContext, Pagination } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { Link } from 'react-router-dom';
import BulkAssignButton from '../AdminUser/Action/Bulk.Assign.Button';



const NoneActions = props => {
    return null;
};

const ItemEditButton = ({ carouselid, ...props }) => {
    const record = useRecordContext();
    console.log(record);
    return (
        <div>
            <Button component={Link} to={{ pathname: `/sitecarouselslide/${record.id}`, search: `?slideid=${record.site_carousel_id}`, }} label="Edit" onClick={(event) => { event.stopPropagation() }}>
                <EditOutlinedIcon />
            </Button>
        </div>
    )
}

const SiteCarouselSlideActionsButtons = ({ ...props }) => {
    return (<Fragment>
        <BulkDeleteButton {...props} undoable={false}
            confirmTitle="Delete File Record"
            confirmContent={"Are you sure you want to delete this record?"} />


    </Fragment>)
}
const SiteCarouselDetail = ({ ...props }) => {
    const carouselid = props.id;
    const classes = useStyles();
    return (<ShowController {...props}>
        {controllerProps =>
            <Show title="SiteCarousel Detail" {...props}>
                <TabbedShowLayout>
                    <Tab label="Summary">
                        <TextField source="site" label="Site" />
                        <TextField source="page" label="Page" />
                        <TextField source="speed" label=" Speed" />
                        <TextField source="intervaltime" label=" Interval Time" />
                        <TextField source="autoplay" label=" Autoplay" />
                        <TextField source="animation" label="Animation" />
                        <TextField source="navigation" label="Navigation" />
                        <TextField source="status" label="Status" />
                    </Tab>
                    <Tab label="SiteCarousel Slide" path="sitecarouselslide">
                        <ReferenceManyField reference='sitecarouselslide' target="slide_id" addLabel={false}>
                            <Datagrid rowClick="show">
                                <TextField source="title" label="Title" />
                                <TextField source="intro" label="Intro" />
                                <TextField source="link_text" label="Link Test" />
                                <TextField source="position" label="Content Position" />
                                <TextField source="status" label="Status" />
                                <TextField label="Order" source="order"/>
                                <ItemEditButton carouselid={carouselid} cellClassName={classes.actionCell} sortable={false} />
                            </Datagrid>
                        </ReferenceManyField>                       
                    </Tab>
                </TabbedShowLayout>
            </Show>
        }
    </ShowController>
    )
};
const useStyles = makeStyles({
    row: { verticalAlign: "top" },
    actionCell: { width: 100 },
    imageCell: { width: 160 },
    idCell: { width: 80 }
});
export default SiteCarouselDetail