
export const WithdrawPaymentInfoUpload = (data) => {
    var formdata = new FormData()
    console.log(data);

    if (data.id) {
        formdata.append('id', data.id)
    }
    if (data.task_date) {
        formdata.append('task_date', data.task_date)
    }
    if (data.message) {
        formdata.append('message', data.message)
    }
    if (data.type) {
        formdata.append('type', data.type)
    }
    if(data.attachments){
        formdata.append('attachments',data.attachments.rawFile)
    }
    return formdata;
}