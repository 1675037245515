import React, { Fragment } from "react";
import {
  List,
  Datagrid,
  Filter,
  TextInput,
  TextField,
  EditButton,
  BulkDeleteButton,
  DeleteButton,
  FunctionField,
} from "react-admin";
import { VideoTypeSelectInput } from "../../UI/Input/SelectInput";
import useCheckPermissions from '../AdminPermissions/useCheckPermissions'

const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />

  </Filter>
);
const ListActionButtons = ({ ...props }) => {
  return (
    <>
      <BulkDeleteButton
        {...props}
        undoable={false}
        confirmTitle="Delete File Record"
        confirmContent={"Are you sure you want to delete this record?"}
      />
    </>
  );
};
const BasicPlanList = ({ ...props }) => {
  return (
    <List
      {...props}
      title="Plan"
      bulkActionButtons={<ListActionButtons />}
      filters={<ListFilter />}

    >
      <Datagrid rowClick={"show"}>
        <TextField source="id" />

        <TextField source="plan_name" label="Plan Name" />
        <TextField source="plan_amount" label="Plan Amount" />
        <TextField source="video_amount" label="Video Amount" />
        <TextField source="video_count" label="Video Count" />
        <FunctionField source="status" label="Status" render={record => {
          if (record['status'] === 'Deactive') {
            return (<span style={{ color: 'red' }}>Deactive</span>)
          } else {
            return (<span style={{ color: 'green' }}>Active</span>)
          }
        }} />
        <TextField source="public_video_count" label="Public Video Count" />
        <TextField source="public_video_amount" label="Public Video Amount" />
        {useCheckPermissions('BasicPlan_View')?<EditButton />:null}
        {useCheckPermissions('BasicPlan_Delete')?<DeleteButton />:null}
      </Datagrid>
    </List>
  );
};

export default BasicPlanList;
