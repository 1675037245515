import { fetchGet, fetchPut } from '../Service/ApiService'
import { authmodel } from '../Provider/AuthProvider';

export const deleteSiteCarouselSlideImage = (sliderslideid, imagekey, resolve, reject) => {

    try {
        fetchPut(`sitecarouselslide/`+sliderslideid+'/sitecarouselslideimage/delete', {"key":imagekey}, {
            success: (res) => { resolve(res)},
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to delete image" })
    }
};