import React from 'react';
import {SimpleForm, Toolbar, SaveButton, Button, useNotify, useRefresh } from 'react-admin';
import IconCancel from "@material-ui/icons/Cancel";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import { deleteSiteCarouselSlideImage } from "../../Model/SiteCarouselSlide";


const SiteCarouselSlideImageDelete = props =>{
    const notify = useNotify()
    const refresh = useRefresh()
    return (<SiteCarouselSlideImageDeleteForm {...props} onSave={()=>{ refresh() }} onMessage={(msg)=>{  notify(msg,'warning')  }} />)
}

class SiteCarouselSlideImageDeleteForm extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            saving : false,
            SiteCarouselSlideimage: this.props.SiteCarouselSlideImage
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }
  
    componentWillReceiveProps(nextProps){
        this.setState({
            SiteCarouselSlideimage: nextProps.SiteCarouselSlideImage
        })
    }

    handleSubmit(values, redirect){
        const { saving, SiteCarouselSlideimage } = this.state
        const { onMessage, onSave, onClose, record } = this.props
        if(saving) return;

        this.setState({saving:true})
        
        deleteSiteCarouselSlideImage(record.id,SiteCarouselSlideimage.key, (res)=>{
            onSave()            
            onClose()
        },(res)=>{
            onMessage(res.message)
            this.setState({saving:false})
        })
    };

    render(){
        const { saving, SiteCarouselSlideimage } = this.state
        const { onClose, record } = this.props 
        
        if(!SiteCarouselSlideimage)return null;

        return (
            <Dialog fullWidth open={true} onClose={()=>{ onClose(); }}>
                <DialogTitle>Delete Offer Image</DialogTitle>
                <SimpleForm basePath="" resource="sitecarouselslide" record={record} save={this.handleSubmit} saving={saving} toolbar={<FormToolbar onCancel={()=>{ onClose(); }}/>} redirect={false}>
                    <p style={{padding:0, margin:0, paddingBottom:30}}>Are you sure!. Do you wnat to delete this image.</p>
                </SimpleForm>
            </Dialog>
        )
    }
}
  
  
const FormToolbar = props => (
  <Toolbar {...props} >
      <SaveButton label="Delete" redirect={null} submitOnEnter={false} />
      {!props.saving && <Button label="ra.action.cancel" onClick={props.onCancel}>
          <IconCancel />
      </Button>}
  </Toolbar>
)


export default SiteCarouselSlideImageDelete