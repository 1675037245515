export const onSiteCarouselSlideCreate = (values) => {

    const errors = {}
    if (!values.site_carousel_id) {
        errors.site_carousel_id = ['Site Carousel is required']
    }
    if (!values.title) {
        errors.title = ['Title is required']
    }else if(maxLength(70,values.title)){
        errors.title = ['The Title may not be greater than 70 characters.']
    }
    if (!values.intro) {
        errors.intro = ['Intro is required']
    }else if(maxLength(150,values.intro)){
        errors.intro = ['The Intro may not be greater than 150 characters.']
    }
    if (!values.link) {
        errors.link = ['Link is required']
    }
    if (!values.link_text) {
        errors.link_text = ['link Text is required']
    }else if(maxLength(30,values.link_text)){
        errors.link_text = ['The Link Text may not be greater than 30 characters.']
    }
    if (!values.status) {
        errors.status = ['Status is required']
    }
    return errors

};

export const onSiteCarouselSlideEdit = (values) => {

    const errors = {}
    if (!values.site_carousel_id) {
        errors.site_carousel_id = ['Site Carousel is required']
    }
    if (!values.title) {
        errors.title = ['Title is required']
    }else if(maxLength(70,values.title)){
        errors.title = ['The Title may not be greater than 70 characters.']
    }
    if (!values.intro) {
        errors.intro = ['Intro is required']
    }else if(maxLength(150,values.intro)){
        errors.intro = ['The Intro may not be greater than 150 characters.']
    }
    if (!values.link) {
        errors.link = ['Link is required']
    }
    if (!values.link_text) {
        errors.link_text = ['link Text is required']
    }else if(maxLength(30,values.link_text)){
        errors.link_text = ['The Link Text may not be greater than 30 characters.']
    }
    if (!values.status) {
        errors.status = ['Status is required']
    }
  

    return errors
};

const isEmpty = (value: any) =>{
    return (typeof value === 'undefined' || value === null || value === '')
}

export const maxLength = (length, value) =>{
    return (!isEmpty(value) && value.length > length)
}